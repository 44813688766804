<template>
  <div class="equipment" id="ruleWrapper">
    <div class="e_content bgFFF" style="padding: 20px;">
      <div class="ruleMessage1">
        <el-form
          ref="ruleForm2"
          label-width="140px"
          class="demo-ruleForm"
          :model="formInline"
        >
          <div class="ruleMessage">
            <h2 class="title">
              <div class="title_icon"></div>
              欠费离场规则信息
            </h2>
            <el-row>
              <el-col :span="7" :offset="1">
                <el-form-item
                  :label="$t('searchModule.Rule_Name')"
                  prop="debtLeaveRuleName"
                  :rules="rules2.debtLeaveRuleName"
                  :disabled="!bandMSFeeScene"
                >
                  <el-input
                    type="text"
                    placeholder="请输入规则名称"
                    v-model.trim="formInline.debtLeaveRuleName"
                    :disabled="!bandMSFeeScene"
                    auto-complete="off"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="2">
                <el-form-item
                  label="规则编码"
                  prop="debtLeaveRuleCode"
                  :rules="rules2.debtLeaveRuleCode"
                  :disabled="!bandMSFeeScene"
                >
                  <el-input
                    placeholder="请输入规则编码"
                    :disabled="!bandMSFeeScene"
                    v-model.trim="formInline.debtLeaveRuleCode"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item
                  :label="$t('searchModule.Merchant_affiliation')"
                  prop="operationId"
                  :rules="rules2.operationId"
                  :disabled="!bandMSFeeScene"
                >
                  <el-select
                    :disabled="this.isEdit || !bandMSFeeScene"
                    v-model.trim="formInline.operationId"
                    filterable
                    style="width: 300px"
                    size="15"
                  >
                    <el-option
                      :label="value.operationName"
                      :value="value.operationId"
                      :key="value.operationId"
                      v-for="value in tenantList"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" :offset="1"></el-col>
            </el-row>
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item
                  label="描述"
                  prop="description"
                  :rules="[
                    {
                      required: true,
                      message: '描述不能为空！',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    type="textarea"
                    :maxlength="150"
                    :rows="8"
                    placeholder="请输入描述"
                    v-model="formInline.description"
                    style="width: 370px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item label="生效日期" :rules="rules2.date">
                  <el-date-picker
                    v-model.trim="effectiveDate"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions0"
                    :disabled="isEdit"
                    >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div
            v-for="(rule, index) in formInline.property"
            :key="index"
          >
            <!-- 设置生效日期 -->
            <!-- <div class="timerPiker">
              <el-form-item label="生效日期" :rules="rules2.date">
                <el-date-picker
                  v-model.trim="rule.date"
                  type="date"
                  :disabled="rule.disabled"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  @change="() => timeChange(rule, index)"
                  :picker-options="pickerOptions0"
                ></el-date-picker>
                <el-button
                  v-if="!index"
                  @click="addRule(index)"
                  style="float: right"
                  >添加生效日期</el-button
                >
                <span
                  v-if="index"
                  class="iconfont icon-shanchu-copy"
                  style="float: right"
                  @click="removeRule(rule)"
                  :style="{
                    'border-color': 'red',
                    color: 'red',
                    'font-size': '35px',
                  }"
                ></span>
              </el-form-item>
              <div class="errMsg" style="padding-top: 5px">
                {{ rule.errMsg }}
              </div>
            </div> -->
            <div class="ruleMessage">
              <h2 class="title">
                <div class="title_icon"></div>
                车场欠费规则配置
              </h2>
              <!-- 车场欠费总金额 -->
              <el-row>
                <el-col :span="10" :offset="1">
                  <el-form-item
                    label="车场欠费总金额 ≤"
                    :prop="'property.' + index + '.parkDebtTotalMoney'"
                    :rules="[
                      { required: true, message: '请输入金额', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      placeholder="请输入车场欠费总金额"
                      v-model="rule.parkDebtTotalMoney"
                      style="width: 200px"
                      :disabled="rule.disabled"
                      @input="rule.parkDebtTotalMoney=rule.parkDebtTotalMoney.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                    ></el-input>
                    <span>&nbsp;&nbsp;元</span>
                    <el-tooltip placement="top" style="margin-left: 20px;">
                      <div slot="content">
                        0表示不限制金额
                        <br />
                      </div>
                      <span class="help">?</span>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 欠费时间段+ -->
              <div
                v-for="(detail, index1) in rule.periodRules"
                :key="index1"
                style="margin-top: 50px"
              >
                <el-form-item
                  :rules="rules2.startDate"
                  :label="'欠费离场时段' + (index1 + 1)"
                  style="display: inline-block"
                >
                  <!-- <el-tooltip placement="top" class="tooltip1">
                    <div slot="content">
                      时段：时段总和必须为24小时，例如：时段1+时段2 = 24小时
                      <br />
                    </div>
                    <span class="help">?</span>
                  </el-tooltip> -->
                  <el-time-picker
                    value-format="HH:mm:ss"
                    v-model="detail.startTime"
                    :disabled="detail.disabled"
                    placeholder="任意时间点"
                    :picker-options="{ format: 'HH:mm:ss' }"
                  ></el-time-picker> </el-form-item
                >至
                <el-form-item
                  :rules="rules2.endDate"
                  label-width="10px"
                  style="display: inline-block"
                >
                  <el-time-picker
                    value-format="HH:mm:ss"
                    v-model="detail.endTime"
                    :disabled="detail.disabled"
                    placeholder="任意时间点"
                    :picker-options="{ format: 'HH:mm:ss' }"
                  ></el-time-picker>
                </el-form-item>
                <span
                  @click="detailOpreate(index, index1, detail)"
                  :class="
                    index1 ? 'iconfont icon-shanchu-copy' : 'iconfont icon-jia'
                  "
                  v-if="!detail.disabled"
                  :style="{
                    'border-color': index1 ? 'red' : 'blue',
                    color: index1 ? 'red' : 'blue',
                    'font-size': '30px',
                  }"
                ></span>
                <el-tooltip placement="top" style="margin-left: 10px;transform: translateY(-5px);">
                      <div slot="content">
                        00:00:00至00:00:00表示全天支持欠费离场
                        <br />
                      </div>
                      <span class="help">?</span>
                    </el-tooltip>
              </div>
            </div>
            <!-- 3 -->
            <div class="ruleMessage">
              <!-- 单次欠费金额、欠费总次数、欠费总金额 -->
              <h2 class="title">
                <div class="title_icon"></div>
                车辆欠费规则配置
              </h2>
              <el-row>
                <el-col :span="10" :offset="1">
                  <el-form-item
                    label="单次欠费金额 ≤"
                    :prop="'property.' + index + '.preMaxMoney'"
                    :rules="[
                      { required: true, message: '请输入金额', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      placeholder="请输入单次欠费金额"
                      v-model="rule.preMaxMoney"
                      style="width: 200px"
                      :disabled="rule.disabled"
                      @input="rule.preMaxMoney=rule.preMaxMoney.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                    ></el-input>
                    <span>&nbsp;&nbsp;元</span>
                    <el-tooltip placement="top" style="margin-left: 20px;">
                      <div slot="content">
                        0表示不限制金额
                        <br />
                      </div>
                      <span class="help">?</span>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="1">
                  <el-form-item
                    label="欠费总次数 ≤"
                    :prop="'property.' + index + '.totalTimes'"
                    :rules="[
                      { required: true, message: '请输入欠费总次数', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      placeholder="请输入欠费总次数"
                      v-model="rule.totalTimes"
                      style="width: 200px"
                      :disabled="rule.disabled"
                      @input="rule.totalTimes=rule.totalTimes.replace(/[^0-9]{0,1}(\d*(?:\\d{0,0})?).*$/g, '$1')"
                    ></el-input>
                    <span>&nbsp;&nbsp;次</span>
                    <el-tooltip placement="top" style="margin-left: 20px;">
                      <div slot="content">
                        0表示不限制金额
                        <br />
                      </div>
                      <span class="help">?</span>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="1">
                  <el-form-item
                    label="欠费总金额 ≤"
                    :prop="'property.' + index + '.totalMoney'"
                    :rules="[
                      { required: true, message: '请输入金额', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      placeholder="请输入欠费总金额"
                      v-model="rule.totalMoney"
                      style="width: 200px"
                      :disabled="rule.disabled"
                      @input="rule.totalMoney=rule.totalMoney.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                    ></el-input>
                    <span>&nbsp;&nbsp;元</span>
                    <el-tooltip placement="top" style="margin-left: 20px;">
                      <div slot="content">
                        0表示不限制金额
                        <br />
                      </div>
                      <span class="help">?</span>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>
      </div>
      <div style="margin: 0 auto; width: 200px; margin-bottom: 20px">
        <el-button type="primary" @click="submitData()">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "addArrearsRule",
  data() {
    let checkFreeTime = (rule, value, callback) => {
      if (!/(\d+.\d+)|\d+/.test(value)) {
        callback(new Error("必须为数字"));
      } else if (value < 0 || value > 59) {
        callback(new Error("数字必须在0-59之间"));
      } else {
        callback();
      }
    };
    let checkCode = (rule, value, callback) => {
      value = value.replace(/\s/g, "");
      if (/[\u4E00-\u9FA5]/.test(value)) {
        return callback(new Error("编码不能存在汉字"));
      }
      if (!value) {
        callback(new Error("规则编码不能为空"));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error("规则编码长度在2-50之间"));
      } else {
        callback();
      }
    };
    let checkName = (rule, value, callback) => {
      var regEn = /[`~!@#$%^&*()_+<>?:"{},./;'[\]]/im,
        regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (!value) {
        callback(new Error("规则名称不能为空"));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error("规则名称长度在2-50之间"));
      } else if (regEn.test(value) || regCn.test(value)) {
        callback(new Error("名称不能包含特殊字符"));
      } else {
        callback();
        // this.$axios.get('/acb/2.0/bacb/fee/byFeeName/' + value).then(res => {
        //         if (res.state != 0) {
        //             if (this.isEdit && this.oldName == value) {
        //                 callback();
        //             } else {
        //                 callback(new Error('规则名称已经存在'));
        //             }
        //         } else {
        //             callback();
        //         }
        // })
      }
    };
    return {
      effectiveDate: [], // 生效日期
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      copyData: [],
      oldFormInline: {},
      timeCheck: true,
      showAdd: true,
      first1: false,
      first2: false,
      isEdit: false,
      Dateflag: true,
      debtLeaveRuleId: "",
      errMsg: "",
      equipmentId: "",
      tenantList: [],
      formInline: {
        effectiveStartDate: "", // 生效日期？？？？
        effectiveEndDate: "", // 生效日期？？？？
        // templateId: 120,
        description: "",
        debtLeaveRuleName: "",
        operationId: "",
        bandMSFeeScene: 0,
        debtLeaveRuleCode: "",
        property: [
          {
            parkDebtTotalMoney: "", // 车场欠费总金额??????
            preMaxMoney: "", // 单次欠费金额??????
            totalTimes: "", // 欠费总次数??????
            totalMoney: "", // 欠费总金额??????
            freeTime: "", //  免费停车时长，单位分钟
            unitDelay: true, //  跨段延续计费 1 = 是 0 = 否
            freeTimeAble: true, //  免费分钟数纳入计费时间 1 = 是 0 = 否
            unitUp: "", //  不满计费单元部分补齐计费单元收费 1 = 是 0 = 否
            date: "", //  计费规则生效日期
            periodRules: [
              {
                startTime: "00:00:00", // 时段开始时间
                endTime: "00:00:00", // 时段结束时间
                limit: 0, // 时段最高收费
                items: [
                  {
                    type: 0, // 0 = 基础时段（一个时段只能有一个区间配置）
                    threshold: 0, // 费率周期阈值，单位:分钟 ,大于本值执行该费率，否则舍弃，本字段对于时间段计费有效
                    period: 0, //  时间单位  分钟
                    unit: 0, //  价格单位  元
                    segment: 0, //  时间分段  分钟
                    sub: [
                      {
                        type: 0, // 0 = 基础时段（一个时段只能有一个区间配置）
                        threshold: 0, // 费率周期阈值，单位:分钟 ,大于本值执行该费率，否则舍弃，本字段对于时间段计费有效
                        period: 0, //  时间单位  分钟
                        unit: 0, //  价格单位  元
                        segment: 0, //  段时长  分钟
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      rules2: {
        debtLeaveRuleName: [
          {
            required: true,
            message: "请输入规则名称",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        debtLeaveRuleCode: [
          {
            required: true,
            message: "请输入规则编码",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        freeTime: [
          {
            required: true,
            message: "请输入免费分钟数",
            trigger: "blur",
          },
          { validator: checkFreeTime, trigger: "blur" },
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "change",
          },
        ],
        startDate: [
          {
            required: true,
            message: "请选择开始日期",
            trigger: "blur",
          },
        ],
        endDate: [
          {
            required: true,
            message: "请选择结束日期",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: "请输入金额单位",
            trigger: "blur",
          },
        ],
        period: [
          {
            required: true,
            message: "请输入单位分钟",
            trigger: "blur",
          },
        ],
        segment: [
          {
            required: true,
            message: "请输入段时长度",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 日期格式化
    formatDate(date) {
      var y = date.getFullYear();
      console.log(y);
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    },
    freeTimeChange() {
      this.$refs.ruleForm2.validateField("freeTime");
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    checkTime(data1) {
      let data = Object.assign({}, data1);
      for (let i in data.property) {
        let timePeriod = 0;
        for (let j in data.property[i].periodRules) {
          let timePeriod_ = 0;
          if (
            new Date("2017-1-1 " + data.property[i].periodRules[j].endTime).getTime() ==
            new Date("2017-1-1 " + data.property[i].periodRules[j].startTime).getTime()
          ) {
            timePeriod_ = 24 * 60 * 60;
          } else {
            if (
              new Date(
                "2017-1-1 " + data.property[i].periodRules[j].endTime
              ).getTime() == new Date("2017-1-1 00:00:00").getTime()
            ) {
              timePeriod_ =
                1 +
                Math.abs(
                  new Date("2017-1-1 23:59:59").getTime() -
                    new Date(
                      "2017-1-1 " + data.property[i].periodRules[j].startTime
                    ).getTime()
                ) /
                  1000;
            } else {
              timePeriod_ =
                Math.abs(
                  1 *
                    (new Date(
                      "2017-1-1 " + data.property[i].periodRules[j].endTime
                    ).getTime() -
                      new Date(
                        "2017-1-1 " + data.property[i].periodRules[j].startTime
                      ).getTime())
                ) / 1000;
              if (
                1 *
                  (new Date(
                    "2017-1-1 " + data.property[i].periodRules[j].endTime
                  ).getTime() -
                    new Date(
                      "2017-1-1 " + data.property[i].periodRules[j].startTime
                    ).getTime()) <
                0
              ) {
                timePeriod_ = 0 - timePeriod_ + 24 * 60 * 60;
              }
            }
          }
          // if (data.rules[i].periodRules[j].items[0].type == 1) {
          //     data.rules[i].periodRules[j].items[0].period = timePeriod_ / 60;
          // }
          timePeriod += timePeriod_;
        }
        if (timePeriod > 24 * 60 * 60) {
          this.timeCheck = false;
          return;
        } else {
          this.timeCheck = true;
        }
        data1 = data;
      }
    },
    setScroll() {
      this.$nextTick(() => {
        let doc = document.getElementById("content");
        doc.scrollTop = doc.scrollHeight - doc.clientHeight;
      });
    },

    timeChange(rule, index) {
      if (rule.date == "" || rule.date == undefined) {
        rule.errMsg = "生效日期不能为空";
        this.errMsg = false;
      } else {
        this.errMsg = true;
      }
      console.log(66666);
    },
    // 欠费总次数必须为数字
    checkQfzcs(rule, value, callback) {
      // if (!/(\d+.\d+)|\d+/.test(value)) {
      //   callback(new Error("必须为数字"));
      // } else if (value < 0 || value > 9999) {
      //   callback(new Error("数字必须在0-9999之间"));
      // } else {
      //   callback();
      // }
    },
    checkNum(rule, value, callback) {
      // if (value == 0) {
      //   return callback();
      // }
      // if (!/(\d+.\d+)|\d+/.test(value)) {
      //   return callback(new Error("必须为数字"));
      // }
      // if (!value) {
      //   callback(new Error("最高限额不能为空"));
      // } else if (value < 0) {
      //   callback(new Error("最高收费限额应在0-999之间"));
      // } else if (value > 999) {
      //   callback(new Error("最高收费限额应在0-999之间"));
      // } else {
      //   callback();
      // }
    },
    removeRule(item) {
      var index = this.formInline.property.indexOf(item);
      if (this.copyData.length - 1 <= index) {
        this.formInline.property.splice(index, 1);
      }
      this.setScroll();
    },
    addRule(index) {
      this.formInline.property.push({
        freeTime: "", //  免费停车时长，单位分钟
        unitDelay: true, //  跨段延续计费 1 = 是 0 = 否
        freeTimeAble: true, //  免费分钟数纳入计费时间 1 = 是 0 = 否
        unitUp: "", //  不满计费单元部分补齐计费单元收费 1 = 是 0 = 否
        date: "", //  计费规则生效日期
        periodRules: [
          {
            startTime: "07:00:00", // 时段开始时间
            endTime: "13:00:00", // 时段结束时间
            limit: "0", // 时段最高收费
            items: [
              {
                type: 0, // 0 = 基础时段（一个时段只能有一个区间配置）
                threshold: 0, // 费率周期阈值，单位:分钟 ,大于本值执行该费率，否则舍弃，本字段对于时间段计费有效
                period: 0, //  时间单位  分钟
                unit: 0, //  价格单位  元
                segment: 0, //  时间分段  分钟
                sub: [
                  {
                    type: 0, // 0 = 基础时段（一个时段只能有一个区间配置）
                    threshold: 0, // 费率周期阈值，单位:分钟 ,大于本值执行该费率，否则舍弃，本字段对于时间段计费有效
                    period: 15, //  时间单位  分钟
                    unit: 0, //  价格单位  元
                    segment: 0, //  段时长  分钟
                  },
                ],
              },
            ],
          },
        ],
      });
      this.setScroll();
    },
    detailOpreate(index, index1, detail) {
      if (!index1) {
        this.formInline.property[index].periodRules.push({
          startTime: "00:00:00", // 时段开始时间
          endTime: "00:00:00", // 时段结束时间
          limit: 0, // 时段最高收费
          items: [
            {
              type: 0, // 0 = 基础时段（一个时段只能有一个区间配置）
              threshold: 0, // 费率周期阈值，单位:分钟 ,大于本值执行该费率，否则舍弃，本字段对于时间段计费有效
              period: 0, //  时间单位  分钟
              unit: 0, //  价格单位  元
              segment: 0, //  时间分段  分钟
              sub: [
                {
                  type: 0, // 0 = 基础时段（一个时段只能有一个区间配置）
                  threshold: 0, // 费率周期阈值，单位:分钟 ,大于本值执行该费率，否则舍弃，本字段对于时间段计费有效
                  period: 0, //  时间单位  分钟
                  unit: 0, //  价格单位  元
                  segment: 0, //  段时长  分钟
                },
              ],
            },
          ],
        });
      } else {
        var ind = this.formInline.property[index].periodRules.indexOf(detail);
        if (ind !== -1) {
          if (index <= this.length - 1) {
          } else {
            this.formInline.property[index].periodRules.splice(ind);
          }
        }
      }
    },
    submitData() {
      this.$refs.ruleForm2.validate((valid) => {
        // 生效日期赋值
        console.log(this.effectiveDate, '生效日期');
        this.formInline.effectiveStartDate = this.effectiveDate[0];
        this.formInline.effectiveEndDate = this.effectiveDate[1];
        // console.log(valid);
        if (valid) {
          this.checkTime(this.formInline);
          if (!this.timeCheck) {
            this.$alert("同一生效日期内，时段之和不能大于24小时！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          let copyData1 = JSON.stringify(this.formInline);
          let copyData = JSON.parse(copyData1).property;
          let date = [];
          for (let i = 0; i < copyData.length; i++) {
            if (date.includes(copyData[i].date)) {
              this.$alert("生效时间不能为同一天！");
              return;
            } else {
              date.push(copyData[i].date);
            }
            copyData[i].unitDelay = copyData[i].unitDelay == true ? 1 : 0;
            copyData[i].freeTimeAble = copyData[i].freeTimeAble == true ? 1 : 0;
            copyData[i].unitUp = copyData[i].unitUp == true ? 1 : 0;
            copyData[i].freeTime = parseInt(copyData[i].freeTime);
            delete copyData[i].disabled;
            // console.log(copyData[i].periodRules);
            for (let j = 0; j < copyData[i].periodRules.length; j++) {
              copyData[i].periodRules[j].items[0].period = parseInt(
                copyData[i].periodRules[j].items[0].period
              );
              copyData[i].periodRules[j].items[0].unit = parseFloat(
                copyData[i].periodRules[j].items[0].unit
              );
              copyData[i].periodRules[j].items[0].segment = parseInt(
                copyData[i].periodRules[j].items[0].segment
              );
              if (copyData[i].periodRules[j].items[0].type != 1) {
                copyData[i].periodRules[j].limit = parseFloat(
                  copyData[i].periodRules[j].limit
                );
              }
              let startTime = new Date();
              let endTime = new Date();
              delete copyData[i].periodRules[j].disabled;
              let startArr = copyData[i].periodRules[j].startTime.split(":");
              let endArr = copyData[i].periodRules[j].endTime.split(":");
              startTime.setHours(startArr[0], startArr[1], startArr[2]);
              endTime.setHours(endArr[0], endArr[1], endArr[2]);
              let segment = 1440;
              if (endTime.getTime() - startTime.getTime() < 0) {
                segment = (endTime.getTime() - startTime.getTime()) / 60000 + 24 * 60;
              } else if (
                endTime.getTime() - startTime.getTime() == 0 &&
                copyData[i].periodRules[j].startTime == "00:00:00"
              ) {
                segment = 1440;
              } else {
                segment = (endTime.getTime() - startTime.getTime()) / 60000;
              }
              if (copyData[i].periodRules[j].items[0].type != 0) {
                copyData[i].periodRules[j].items[0].segment =
                  segment == 0 ? 1440 : segment;
                if (copyData[i].periodRules[j].items[0].type == 1) {
                  copyData[i].periodRules[j].items[0].period =
                    segment == 0 ? 1440 : segment;
                }

                delete copyData[i].periodRules[j].items[0].sub;
              } else {
                copyData[i].periodRules[j].items[0].sub[0].period = parseInt(
                  copyData[i].periodRules[j].items[0].sub[0].period
                );
                copyData[i].periodRules[j].items[0].sub[0].unit = parseFloat(
                  copyData[i].periodRules[j].items[0].sub[0].unit
                );
                copyData[i].periodRules[j].items[0].sub[0].segment = parseInt(
                  segment - copyData[i].periodRules[j].items[0].segment
                );
              }
              delete copyData[i].periodRules[j].items;
              delete copyData[i].periodRules[j].limit;
            }

            delete copyData[i].freeTime;
            delete copyData[i].unitDelay;
            delete copyData[i].freeTimeAble;
            delete copyData[i].unitUp;
            delete copyData[i].date;

            copyData[i].periodRules.sort((a, b) => {
              let astart = new Date();
              let bstart = new Date();
              let astartArr = a.startTime.split(":");
              let bstartArr = b.startTime.split(":");
              astart.setHours(astartArr[0], astartArr[1], astartArr[2]);
              bstart.setHours(bstartArr[0], bstartArr[1], bstartArr[2]);
              if (astart.getTime() - bstart.getTime() < 0) {
                return -1;
              } else {
                return 1;
              }
            });
          }

          // let data = JSON.stringify({ id: 120, rules: copyData });
          let data = JSON.stringify(copyData[0]);
          let method = this.isEdit ? "post" : "post";
          let url = !this.isEdit
            ? "/acb/2.0/bacb/debtLeaveRule/add" // 添加
            : "/acb/2.0/bacb/debtLeaveRule/update"; // 修改
          this.$axios[method](url, {
            data: Object.assign({}, this.formInline, { property: data }),
          }).then((res) => {
            if (res.state == 0) {
              this.$router.go(-1);
            } else {
              this.$alert(res.desc);
            }
          });
        } else {
          // console.log("error submit!!");
          if (this.errMsg != true) {
            // this.$alert("提交失败，请保证信息无误", this.$t('pop_up.Tips'), {
            //   confirmButtonText: this.$t('pop_up.Determine'),
            // });
          }
          this.Dateflag = true;
          return false;
        }
      });
    },
    getRule() {
      this.$axios.get("/acb/2.0/bacb/debtLeaveRule/getById/" + this.debtLeaveRuleId).then((res) => {
        if (res.state == 0) {
          this.formInline = res.value;
          // 生效日期赋值
          this.effectiveDate = [this.formInline.effectiveStartDate || '', this.formInline.effectiveEndDate || ''];

          this.formInline.templateId = 120;
          let copyData = [JSON.parse(res.value.property)];
          for (let i = 0; i < copyData.length; i++) {
            copyData[i].disabled = true;
            for (let j = 0; j < copyData[i].periodRules.length; j++) {
              copyData[i].periodRules[j].disabled = true;
            }
            copyData[i].unitDelay = copyData[i].unitDelay == 1 ? true : "";
            copyData[i].freeTimeAble =
              copyData[i].freeTimeAble == 1 ? true : "";
            copyData[i].unitUp = copyData[i].unitUp == 1 ? true : "";
          }
          this.formInline.property = copyData;
          this.copyData = copyData;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  watch: {
    formInline: {
      handler: function (newValue, oldValue) {
        return newValue;
      },
      deep: true,
    },
  },
  created() {
    this.getTenantList();
  },
  activated() {},
  computed: {
    bandMSFeeScene: function () {
      return this.formInline.bandMSFeeScene == 0;
    },
  },
  mounted() {
    if (this.$route.query.debtLeaveRuleId) {
      this.isEdit = true;
      this.first1 = true;
      this.first2 = 2;
      this.debtLeaveRuleId = this.$route.query.debtLeaveRuleId;
      this.getRule();
    } else {
      this.effectiveDate = [this.formatDate(new Date()), this.formatDate(new Date(new Date().getTime() + 3600 * 1000 * 24 * 30))];
    }
  },
};
</script>
<style scoped>
.tooltip1 {
  position: absolute !important;
  left: -85px;
  top: 8px;
  z-index: 1;
}
.fuhao {
  display: inline-block;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 16px;
  text-decoration: none;
  border: 2px solid;
  border-color: blue;
  font-size: 30px;
  vertical-align: middle;
}
.equipment .e_content {
  border: 1px solid #c0ccda;
  border-radius: 4px;
  min-height: 550px;
  padding: 0 20px;
  color: #48576a;
}
.equipment .e_content .f-top {
  width: 700px;
  margin: 0 auto;
  margin-bottom: 80px;
}
.el-select {
  width: 250px;
}
.breadcrumb {
  height: 35px;
}
.ruleMessage {
  padding: 10px;
  /* border: 1px solid #c0ccda; */
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 20px;
}
/* .title {
  line-height: 40px;
  padding-left: 20px;
} */
.timerPiker {
  padding-left: 20px;
  position: relative;
}
.timerPiker .errMsg {
  position: absolute;
  left: 120px;
  top: 39px;
  font-size: 12px;
  color: #ff4949;
}
.effectDate {
  margin-right: 10px;
}
.dateForm {
  width: 700px;
  margin: 0 auto;
}
b {
  text-align: center;
  font-weight: normal;
  display: inline-block;
  width: 36px;
  line-height: 36px;
  background: #c0ccda;
}
.addEffictDate {
  display: inline-block;
  cursor: pointer;
}
.addEffictDate:hover {
  color: blue;
}
.rule {
  padding: 0 50px;
}
.help {
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 20px;
  line-height: 20px;
  vertical-align: middle;
  background: #c0ccda;
  position: relative;
}
.timeMax label {
  padding: 0 !important;
}
</style>
